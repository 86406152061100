import {
  Divider,
  Stack,
  Text,
  Container,
  Box,
  HStack,
  Center
} from "@chakra-ui/react";
import ProfileArray from "./ProfileArray";
import Languages from "./Languages.js";

export default function About({ color }) {
  const profile = ProfileArray();
    return (
      <>
        <Container maxW={"3xl"} id="about">
          <Stack
            as={Box}
            textAlign={"center"}
            spacing={{ base: 8, md: 5 }}
            pb={{ base: 20, md: 10 }}
          >
            <Text color={"gray.600"} fontSize={"xl"} px={4}>
              {profile.about}
            </Text>
            <Text fontWeight={800}>Languages</Text>
            <Center>
            <Languages languages={[
              {text: "Flutter", colour: "blue"}, 
              {text: "Dart", colour: "blue"}, 
              {text: "Swift", colour: "orange"},
              {text: "Python", colour: "red"},
              {text: "C", colour: "gray"},
              {text: "Java", colour: "yellow"},
              {text: "Go", colour: "blue"},
              ]}></Languages>
              </Center>
          </Stack>
        </Container>
      </>
    );
}


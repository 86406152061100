import {
  Tag,
  HStack
} from "@chakra-ui/react";

export default function Languages({languages}) {
    return (<HStack spacing={4}>
    {languages.map((language) => (
        <Tag size="sm" key={language.text} variant='solid' colorScheme={language.colour}>
        {language.text}
        </Tag>
    ))}
    </HStack>);
}